import React, { useState, useEffect } from "react";
import { useLocation, navigate } from "@reach/router";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/Breadcrumb";
import { Container, Col, Row } from "react-bootstrap";
import axios from 'axios';

const MoodPage = () => {
    const apiUrl = process.env.GATSBY_API_URL;
    const [url, setUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const submit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        try {
            const response = await axios.post(`${apiUrl}what`, { url });

            if (response.data.success) {
                window.location.href = `/what-da-fuck-is-a-cookie/${response.data.id}`;
            } else {
                setErrorMessage('Failed to process the URL. Please try again.');
            }
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage('An error occurred. Please try again later.');
        }
    };

  
    return (
        <Layout>
            <SEO title={`WHAT THE FUCK IS A COOKIE??`}
                 description={``} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Breadcrumb />
                        <h1 style={{fontSize: 72}}>WHAT DA FUCK IS A COOKIE???</h1>
                        <p>{">"} SITES THAT GIVE YOU `THREE FREE ARTICLES A MONTH` OR WHATEVER USUALLY USE <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank"><b>COOKIES</b></a> TO DETERMINE HOW MANY ARTICLES YOU'VE READ THIS MONTH</p>
                        <p>{"> IF YOU REQUEST THE WEBSITE WITHOUT COOKIES YOU CAN READ UNLIMITED ARTICLES. THAT'S WHAT THIS SITE DOES."}</p>
                        <p>
                            {"> COPY AN ENTIRE URL AND PASTE IT IN THE TEXT BOX BELOW. INCLUDE THE LEADING "}
                            <span style={{ fontFamily: "monospace" }}>https://www.</span>
                        </p>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        <form onSubmit={submit} className="flex space-y-4">
                            <input
                                type="text"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                placeholder="Paste the URL here"
                                className="px-4 py-2 flex-grow border border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                            <button
                                type="submit"
                                className="px-4 py-2 h-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-0"
                            >
                                Submit
                            </button>
                        </form>
                    </Col>
                </Row>    
            </Container>
        </Layout>
    );
};

export default MoodPage;